.fancy-box img {
    max-width: 100%;
    width: 360px;
    height: 420px;
    object-fit: cover;
    object-position: left;
    box-shadow: 0 5px 20px 2px rgba(141, 141, 141, 0.2);
    margin-bottom: 25px;
}

