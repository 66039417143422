.contacts {
    padding: 100px 0;
}

 .breadcrumb a {
    text-decoration: none;
    color: #291258;
    font-family: "RuberoidRegular";
}

 .breadcrumb li {
    margin-left: 5px;
}

 .breadcrumb li span {
    font-family: "RuberoidBold";
}

.contacts h1 {
    margin: 30px 0;
    font-family: "RuberoidBold";
    font-size: 36px;
    color: #291258;
}

.contacts .contacts-about p {
    font-family: "RuberoidRegular";
    color: rgb(155, 155, 155);
    margin-bottom: 0;
}

.contacts .contacts-about p a {
    font-family: "RuberoidRegular";
    color: rgb(155, 155, 155);
}

.contacts .contacts-map iframe {
    margin-top: 40px;
    border-radius: 30px;
}


