.website h1 {
    padding-top: 30px;
    margin-bottom: 25px;
    font-family: "RuberoidBold";
    font-size: 36px;
    color: #291258;
}

.website {
    padding: 100px 10px;
}

.website-card .title {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.wweebb{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.wweebb, .website-card {
    text-align: center;
    height: 100%;
    padding: 30px 40px;
    box-shadow: 0px 0px 29px -9px rgb(0 0 0 / 25%);
    border-radius: 35px;
}

.webmarketbox {
    margin-bottom: 10px;
    padding: 30px 40px;
    box-shadow: 0px 0px 29px -9px rgb(0 0 0 / 25%);
    border-radius: 35px;
}

.website-carddd {
    margin-bottom: 20px;
}

.webmarketbox h2 {
    font-size: 26px;
}

.website .breadcrumb a {
    text-decoration: none;
    color: #291258;
    font-family: "RuberoidRegular";
}

.website .breadcrumb li {
    margin-left: 5px;
}

.website .breadcrumb li span {
    font-family: "RuberoidBold";
}

.wweebb h3, .website-card h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    font-family: "RuberoidBold";
    font-size: 26px;
    color: #291258;
}

.wweebb img, .website .website-card img {
    max-width: 100%;
    margin: 5px 0 25px;
    object-fit: contain;
    height: 190px;
}

.website .website-parag {
    font-family: "RuberoidSemiBold";
    margin-bottom: 50px;
    text-align: start !important;
    font-size: 17px;
    color: #291258;
}

.website .website-card-parag {
    text-align: start;
    font-family: "RuberoidRegular";
    font-size: 14px;
    color: #291258;
}

.website h4 {
    font-family: "RuberoidExtraBold";
    font-size: 22px;
    color: #291258;
    margin-bottom: 40px;
}

.mobileappimage {
    display: flex;
    justify-content: center;
}

.mobileappicon {
    height: 75px;
    max-width: 75px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.website .btn {
    margin: 10px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    color: #fff;
    max-width: 210px;
    height: 64px;
    font-family: "RuberoidExtraBold";
    font-size: 18px;
    letter-spacing: 0.5px;
}

.website .btn:hover {
    color: #291258;
}

.website .col-xl-3 {
    display: flex;
    justify-content: center;
}

.website .linkbox {
    text-decoration: none;
    color: #546688;
    font-family: "RuberoidRegular";
    font-size: 16px;
    min-width: 261px;
    margin-bottom: 25px;
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1px solid #a4acbe;
    transition: 0.3s;
}

.website .linkbox:hover {
    border: 1px solid #291258;
    color: #291258;
}

.website-link-title {
    margin-top: 90px;
}
