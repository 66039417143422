.numbers {
    margin-top: 90px;
}

.numbers .col-lg-3 {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}

.numberbox {
    height: auto;
    max-width: 255px;
    width: 100%;
    padding: 40px 30px;
    border-radius: 20px;
    box-shadow: 0px 0px 29px -9px rgb(0 0 0 / 25%);
}

.numberbox .text-muted {
    font-family: "RuberoidRegular";
}

.numberimage {
    max-height: 70px;
    max-width: 70px;
    height: 100%;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.numberimage img{
    width: 40px;
}

.numberbox h2 {
    font-family: "RuberoidExtraBold";
    font-size: 48px;
    color: #291258;
}

@media screen and (max-width: 500px) {
    .numberimage {
        margin: 6px auto;
    }

    .numberbox {
        text-align: center;
        height: 230px;
        padding: 25px 15px;
    }

    .numberbox h2 {
        font-size: 37px;
    }
}

@media screen and (max-width: 362px) {
    .numbers .row {
        display: block;
    }

    .numbers .col-6 {
        width: auto;
    }

    .numberimage {
        margin: 0 auto 20px;
    }

    .numberbox h2 {
        font-size: 40px;
    }
}

