.article {
    padding: 100px 0;
}

.breadcrumb a {
    text-decoration: none;
    color: #291258;
    font-family: "RuberoidRegular";
}

.breadcrumb li {
    margin-left: 5px;
}
.breadcrumb li span {
    font-family: "RuberoidBold";
}

.article .article-box img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    max-height: 550px;
    height: 550px;
    border-radius: 50px;
}

.article .article-image-box {
    margin: 27px 0;
    width: 100%;
    max-height: 100%;
    height: 550px;
    position: relative;
}

.article th {
    width: 50% !important;
}

.article tbody,
.article td {
    border-width: 1px;
}

.article .article-image-box h1 {
    width: 64%;
    color: #fff;
    background-color: #291258d1;
    position: absolute;
    top: 70px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    height: auto;
}

.article .article-mini {
    font-family: "RuberoidRegular";
    margin-top: 20px;
    color: #546688;
    font-size: 14px;
}

.article h2 {
    font-family: "RuberoidBold";
    color: #291258;
    font-size: 36px;
    margin-bottom: 20px;
}

.article .article-parag {
    color: #173055;
    font-family: "RuberoidRegular";
    font-size: 16px;
}

.article table {
    width: 100%;
    margin: 0 auto;
}

.allposts {
    position: relative;
    margin-bottom: 40px;
}

.blog-posts {
    height: 300px;
   
}

.blog-posts img {
    border-radius: 20px 20px 0 0;
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 300px;
    top: 0;
}

.blog-posts p {
    transition: 0.5s;
    background-color: #291258da;
    bottom: 0;
    color: white;
    width: 92.8%;
    text-align: center;
    padding: 10px 15px;
    font-family: "RuberoidMedium";
    font-size: 20px;
    position: absolute;
    margin-bottom: 0;
    height: 75px;
    overflow: hidden;
}

.blog-posts:hover p {
    border-radius: 20px 20px 0 0;
    height: 100%;
}

@media (max-width: 1399px) {
    .article .article-image-box h2 {
        width: 58%;
    }
    .blog-posts p {
        width: 91.7%;
    }
}

@media (max-width: 1199px) {
    .article .article-image-box h2 {
        width: 640px;
    }
    .blog-posts p {
        width: 95%;
    }
}

@media (max-width: 768px) {
    .article .article-image-box h2 {
        width: 100%;
        font-size: 26px;
    }

    .blog-posts p {
        width: 94%;
    }

    .article tr {
        display: flex;
        flex-direction: column;
        border-bottom-width: 0;
    }

    .article .article-box img {
        height: 300px;
    }

    .article .article-image-box {
        height: 300px;
    }
}

@media (max-width: 500px) {
    .article h2 {
        font-size: 34px;
    }

    .article .article-image-box h2 {
        display: none;
    }
}
