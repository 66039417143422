.section1 {
  padding: 70px 0 50px;
  margin: 0 auto;
  background: #291258;
}

.section1 h1 {
  color: #fff;
  font-size: 57px;
  font-family: "RuberoidExtraBold";
}

.slider-btn-link {
  text-decoration: none;
  color: #fff !important;
  white-space: nowrap;
  padding: 20px;
}

.section1 .parag {
  color: #fff;
  font-size: 18px;
  font-family: "RuberoidSemiBold";
}

.section1 .btn {
  color: white !important;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3f2a69;
  border: 2px solid rgb(102, 61, 119) !important;
  border-radius: 20px;
  max-width: 210px;
  height: 68px;
  font-family: "RuberoidBold";
  font-size: 18px;
  letter-spacing: 0.5px;
}

.section1 .btn:hover {
  background: #291258;
}

.btn:focus {
  box-shadow: none;
}

.rightSlider {
  position: relative;
}

.section1 .slider-box {
  min-height: 550px;
  height: auto;
}

.section1 .sliderImage {
  pointer-events: none;
  user-select: none;
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  max-height: 566px;
  height: auto;
  object-fit: contain;
  padding-top: 30px;
}

.slider-leftside {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.imageBox {
  position: absolute;
  padding: 14px;
  border-radius: 20px;
  max-width: 240px;
  display: flex;
  width: 100%;
  background-color: white;
}

.topbox {
  right: 155px;
  top: 65px;
}

.topbox .imageBoxInner {
  background: linear-gradient(90deg, #959afe 0%, rgba(202, 202, 227, 1) 100%);
  box-shadow: 0 0 19px 2px rgb(149, 154, 254, 66%);
}

.leftbox {
  bottom: 180px;
  left: 0;
}

.leftbox .imageBoxInner {
  background: linear-gradient(90deg, #90d3ff 0%, #b4def8 100%);
  box-shadow: 0 0 19px 2px rgb(144, 211, 255, 66%);
}

.rightbox {
  bottom: 35px;
  right: 55px;
}

.rightbox .imageBoxInner {
  background: linear-gradient(90deg, #ff7676 0%, #fd7f7f 100%);
  box-shadow: 0 0 19px 2px rgb(255 118 118 / 66%);
}

.imageBox p {
  display: flex;
  align-items: center;
  color: #000;
  margin-bottom: 0;
  font-family: "RuberoidBold";
  margin-left: 14px;
}

.imageBox .imageBoxInner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  max-width: 50px;
  width: 100%;
  border-radius: 12px;
}

.section1 .slick-prev,
.section1 .slick-next {
  color: white;
  font-size: 20px;
}

.section1 .slick-dots {
  bottom: -35px;
}

.section1 .slick-dots li.slick-active button:before {
  opacity: 1;
  content: "";
  height: 10px;
}

.section1 .slick-dots li.slick-active {
  width: 20px;
  transition: all 0.3s ease;
  border-radius: 30px;
}

.section1 .slick-dots li {
  transition: all 0.3s ease;
  height: 10px;
  margin: 0 8px;
  background-color: white;
  width: 10px;
  border-radius: 50%;
}

.section1 .slick-dots li button:before {
  content: "";
}

@media screen and (max-width: 1199px) {
  .section1 {
    padding-top: 75px;
  }

  .section1 h1 {
    font-size: 47px;
  }

  .section1 .slider-box {
    min-height: 480px;
  }

  .section1 .parag {
    font-size: 18px;
  }

  .topbox {
    right: 82px;
    top: 48px;
  }
  .leftbox {
    bottom: 180px;
    left: 0;
  }
  .rightbox {
    bottom: 35px;
    right: 55px;
  }
}

@media screen and (max-width: 991px) {
  .section1 {
    padding-top: 84px;
  }

  .section1 .slick-slider .slick-arrow {
    display: none !important;
  }

  .container {
    max-width: 100%;
  }

  .section1 h1 {
    font-size: 42px;
  }

  .section1 .parag {
    font-size: 22px;
  }

  .imageBox {
    max-width: 215px;
    font-size: 14px;
  }

  .topbox {
    right: 40px;
    top: 30px;
  }

  .leftbox {
    bottom: 175px;
    left: 0;
  }

  .rightbox {
    bottom: 35px;
    right: 40px;
  }
}

@media screen and (max-width: 767px) {
  .section1 {
    padding-top: 90px;
  }

  .section1 h1 {
    font-size: 28px;
  }

  .section1 .slider-box {
    min-height: 405px;
  }

  .section1 .parag {
    font-size: 15px;
  }

  .sliderImage {
    margin-top: 30px;
    max-width: 320px;
    height: auto;
  }

  .imageBox {
    opacity: 0.8;
    max-width: 205px;
    font-size: 13px;
  }

  .topbox {
    right: 20px;
    top: 0;
  }

  .leftbox {
    bottom: 180px;
    left: initial;
    right: 20px;
  }

  .rightbox {
    right: 20px;
  }
}

@media screen and (max-width: 710px) {
  .leftbox {
    bottom: 180px;
  }
}

@media screen and (max-width: 627px) {
  .leftbox {
    bottom: 177px;
  }

  .section1 .slider-box {
    min-height: 395px;
  }
}

@media screen and (max-width: 575px) {
  .section1 .btn {
    margin: 0 auto;
  }

  .section1 h1 {
    margin-top: 22px;
    text-align: center;
    font-size: 30px;
  }

  .section1 .parag {
    font-size: 17px;
    text-align: center;
  }

  .sliderImage {
    margin-top: 0;
    max-width: 290px;
  }

  .leftbox {
    bottom: 92px;
  }

  .rightbox {
    bottom: 9px;
  }
}

@media screen and (max-width: 440px) {
  .leftbox {
    bottom: 93px;
  }

  .rightbox {
    bottom: 11px;
  }
}

@media screen and (max-width: 400px) {
  .sliderImage:first-child {
    display: none !important;
  }
  .sliderImage:last-child {
    display: block !important;
    height: 254px;
  }

  .imageBox {
    margin: 0 auto;
    position: static;
    opacity: 1;
    max-width: 250px;
    font-size: 14px;
  }

  .imageBox .imageBoxInner {
    height: 42px;
    max-width: 42px;
  }

  .imageBox .imageBoxInner img {
    width: 25px;
    height: 25px;
  }

  .topbox {
    margin-bottom: 10px;
  }

  .leftbox {
    margin-bottom: 10px;
  }

  .rightbox {
    bottom: 20px;
  }
}
