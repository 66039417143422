.works {
    margin-top: 100px;
}

.works a {
    text-decoration: none;
}

.works .MuiTabs-flexContainer {
    display: block !important;
    text-align: end;
}

.works h2 {
    margin-bottom: -40px;
    font-family: "RuberoidBold";
    font-size: 36px;
    color: #291258;
}

.MuiTabs-scrollButtons.Mui-disabled {
    opacity: 1;
}

.works .PrivateTabIndicator-colorSecondary-3 {
    background-color: #ff7878 !important;
    bottom: 5px;
}

.Mui-selected {
    color: #ff7878 !important;
    background-color: white !important;
}

.MuiTabPanel-root {
    padding: 24px 0 !important;
}

.works .MuiTabScrollButton-root {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.works .css-cjctlb-MuiButtonBase-root-MuiTab-root {
    min-width: auto;
}

.works .main-box:nth-child(even) {
    margin-top: 40px;
}

.works .MuiTab-root {
    margin: 0 13px;
    padding: 0;
    text-transform: unset;
    font-weight: 600;
    font-family: revert;
    font-size: 18px;
    color: #291258;
    opacity: 1;
}

.work-card-layout {
    position: relative;
}

.work-card-layout:hover .portfolio-layout {
    transition: 0.4s;
    opacity: 1;
}

.work-card .main-net {
    border-radius: 20px;
    overflow: hidden;
}

.work-card-layout .net {
    --bs-gutter-x: 0.5;
}

.work-card-layout .net .col-4 {
    border: 0.7px solid rgba(220, 200, 236, 0.795);
}

.work-card-layout .net img {
    border-radius: unset;
    height: 175px;
    margin-bottom: 0 !important;
}

.work-card-layout .portfolio-layout {
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.705);
    padding: 10px;
    font-family: "RuberoidSemiBold";
    color: #291258;
    text-decoration: none;
    font-size: 25px;
}

.works .work-card {
    width: 90%;
}

.work-card h3 {
    margin: 25px 0;
    font-size: 26px;
    color: #291258;
    font-family: "RuberoidBold";
}

.works .work-card img {
    margin-bottom: 20px;
}

.works .PrivateTabIndicator-colorSecondary-3 {
    background-color: #ff7878 !important;
    bottom: 5px;
}

.works .jss5 {
    background-color: #ff7878 !important;
    bottom: 5px;
}

.works .allworks {
    color: #ff7878;
    text-decoration: none;
    font-family: "RuberoidExtraBold";
    font-size: 20px;
    display: inline-block;
}

.works .allworks svg {
    margin-left: 9px;
    transition: 0.3s;
}

.works .allworks:hover svg {
    margin-left: 20px;
}

@media (max-width: 1199px) {
    .works h2 {
        margin-bottom: 30px;
        text-align: center;
    }
}

@media (max-width: 767px) {
    .work-card {
        margin-bottom: 12px;
    }
    .works .main-box:nth-child(even) {
        margin-top: 0;
    }
    .leftcards {
        margin-top: 0;
    }
}

@media (max-width: 675px) {
    .works .MuiTab-root {
        margin-left: 25px;
    }
}

@media (max-width: 599px) {
    .MuiTabs-flexContainer {
        width: 100%;
    }
    .MuiTabs-scrollButtonsDesktop {
        display: block !important;
    }
}
