@font-face {
    font-family: "RuberoidBold";
    src: url(./Ruberoid-Bold.woff2) format("woff2");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "RuberoidExtraBold";
    src: url(./Ruberoid-ExtraBold.woff2) format("woff2");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "RuberoidMedium";
    src: url(./Ruberoid-Medium.woff2) format("woff2");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "RuberoidRegular";
    src: url(./Ruberoid-Regular.woff2) format("woff2");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "RuberoidSemiBold";
    src: url(./Ruberoid-SemiBold.woff2) format("woff2");
    font-display: swap;
    font-style: normal;
}
