.company {
    padding: 100px 10px;
}

.company h1 {
    color: #291258;
    font-size: 36px;
    font-family: "RuberoidBold";
    margin-top: 30px;
    margin-bottom: 60px;
}

.companybox {
    width: 100%;
    padding: 40px 30px;
    border-radius: 20px;
    box-shadow: 0px 0px 29px -9px rgb(0 0 0 / 25%);
    height: 100%;
}

.company h2{
    font-family: "RuberoidBold";
    color: #291258;
}

.companybox .company-parag {
    font-size: 16px;
    color: #546688;
    font-family: "RuberoidRegular";
}

.companyimage {
    height: 70px;
    max-width: 70px;
    width: 100%;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.companyimage img {
    object-fit: contain;
}

.companytitle {
    color: #291258;
    height: 50px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-family: "RuberoidExtraBold" !important;
    font-size: 24px !important;
}

.company .company-head {
    text-align: center;
    margin-top: 90px;
    margin-bottom: 60px;
}

/*model */

.model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000a8;
    transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.model img {
    width: 500px;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.model.open svg {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #ffffff;
    cursor: pointer;
}

@media (max-width: 767px) {
    .company .companyimage {
        margin: 0 auto;
    }
}

@media (max-width: 575px) {
    .company h2 {
        font-size: 27px !important;
    }
}
