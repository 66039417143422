.services{
    padding: 100px 0;
    min-height: 100vh;
}

.services h2 {
    height: 65px;
    font-size: 26px;
    color: #291258;
    font-family: "RuberoidBold";
}

.services h1 {
    margin: 30px 0;
    font-family: "RuberoidBold";
    font-size: 36px;
    color: #291258;
}

.services-title{
    padding-top: 30px!important;
    text-align: start!important;
}


