.error {
    padding: 100px 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error img {
    max-width: 100%;
    width: 100%;
    height: auto;
}

.error h3 {
    font-size: 40px;
}

.error h3,
.error p,
.error .btn {
    background: linear-gradient(to right, #f74e79 0%, #2e2173 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #291258;
    font-family: "RuberoidMedium";
}

.error .btn {
    height: unset!important;
    display: inline-block !important;
    font-family: "RuberoidMedium";
    border: 3px solid skyblue;
    border-image: linear-gradient(to right top, #f74e79 30%, #2e2173 100%);
    text-decoration: none;
    border-image-slice: 1;
}
