.corporate .corporate-header {
    padding-top: 100px;
}

.corporate h1 {
    margin: 20px 0;
    font-family: "RuberoidBold";
    font-size: 36px;
    color: #291258;
}

.corporate .slick-track{
    display: flex;
    align-items: center;
}

.corporate .corporate-title {
    width: 80%;
    margin-bottom: 40px;
    font-size: 24px;
    font-family: "RuberoidRegular";
    color: #546688;
}

.corporate-header-title {
    position: absolute;
}

.corporate .index {
    top: 350px;
    position: absolute;
    left: 0;
    width: 50vw;
}

.corporate .grid {
    height: 75px;
    text-align: center;
    padding-right: 35px;
    margin-bottom: 20px;
    background-color: #90d3ff;
    position: relative;
    width: 66%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.corporate .grid:nth-child(2) {
    width: 73%;
    background-color: #959afe;
}

.corporate .grid:nth-child(3) {
    width: 80%;
    background-color: #ff7676;
}

.corporate .grid:nth-child(4) {
    width: 68%;
    background-color: #eea93a;
}

.corporate .grid .grid-parag {
    padding-right: 15px;
    padding-left: 8px;
    color: #fff;
    font-family: "RuberoidMedium";
    font-size: 17px;
    margin-bottom: 0;
}

.corporate .grid-number {
    border: 1px solid #90d3ff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    top: -5px;
    right: -40px;
    background-color: #fff;
    min-width: 65px;
    height: 65px;
    position: absolute;
    font-family: "RuberoidExtraBold";
    color: #91d3ff;
    font-size: 24px;
}

.corporate .grid:nth-child(2) .grid-number {
    border: 1px solid #959afe;
    color: #959afe;
}

.corporate .grid:nth-child(3) .grid-number {
    border: 1px solid #ff7676;
    color: #ff7676;
}

.corporate .grid:nth-child(4) .grid-number {
    border: 1px solid #eea93a;
    color: #eea93a;
}

.corporate .corporate-img img {
    max-width: 100%;
    width: 564px;
    height: 630px;
    max-height: auto;
    object-fit: contain;
}

.corporate-section {
    padding: 100px 0;
}

.corporate .corporate-col-img img {
    max-width: 100%;
}

.corporate .corporate-col-parag h4 {
    margin-bottom: 30px;
    font-family: "RuberoidBold";
    font-size: 36px;
    color: #291258;
}

.corporate .corporate-col-parag p {
    font-family: "RuberoidRegular";
    font-size: 16px;
    color: #173055;
}

.corporate .application h2 {
    color: #fff;
}

.corporate .application {
    border-bottom: 1px solid rgba(128, 128, 128, 0.74);
}

@media (max-width: 1199px) {
    .corporate .corporate-img img {
        height: 550px;
    }

    .corporate .corporate-title {
        margin-bottom: 25px;
    }

    .corporate .grid {
        height: 70px;
    }
}

@media (max-width: 991px) {
    .corporate .corporate-title {
        width: 100%;
    }

    .corporate .index {
        left: -15px;
        position: static;
        width: 100%;
    }

    .corporate .corporate-img {
        text-align: center;
    }

    .corporate .corporate-img img {
        height: auto;
    }
}
