.phone-button-wrapper {
  position: fixed;
  width: 200px;
  height: 200px;
  bottom: 0px;
  right: 80px;
  cursor: pointer;
  z-index: 200000 !important;
  -webkit-transition: visibility 0.5s;
  -moz-transition: visibility 0.5s;
  -o-transition: visibility 0.5s;
  transition: visibility 0.5s;
}

.phone-button-wrapper .animation-box {
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  position: absolute;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid rgba(30, 30, 30, 0.4);
  opacity: 0.1;
  -webkit-animation: cbh-circle-anim 1.2s infinite ease-in-out;
  -moz-animation: cbh-circle-anim 1.2s infinite ease-in-out;
  -ms-animation: cbh-circle-anim 1.2s infinite ease-in-out;
  -o-animation: cbh-circle-anim 1.2s infinite ease-in-out;
  animation: cbh-circle-anim 2.2s infinite ease-in-out !important;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.phone-button-wrapper .animation-box-fill {
  animation: cbh-circle-fill-anim 2.3s infinite ease-in-out !important;
  opacity: 0 !important;

  width: 100px;
  height: 100px;
  top: 50px;
  left: 50px;
  position: absolute;
  background-color: #888;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  -webkit-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out;
  -moz-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out;
  -ms-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out;
  -o-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

@keyframes cbh-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@keyframes cbh-circle-fill-anim {
  0%,
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }

  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
}

.animation-img-circle {
  width: 60px;
  height: 60px;
  top: 70px;
  left: 70px;
  position: absolute;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNmlDQ1BQaG90b3Nob3AgSUNDIHByb2ZpbGUAAHjarY6xSsNQFEDPi6LiUCsEcXB4kygotupgxqQtRRCs1SHJ1qShSmkSXl7VfoSjWwcXd7/AyVFwUPwC/0Bx6uAQIYODCJ7p3MPlcsGo2HWnYZRhEGvVbjrS9Xw5+8QMUwDQCbPUbrUOAOIkjvjB5ysC4HnTrjsN/sZ8mCoNTIDtbpSFICpA/0KnGsQYMIN+qkHcAaY6addAPAClXu4vQCnI/Q0oKdfzQXwAZs/1fDDmADPIfQUwdXSpAWpJOlJnvVMtq5ZlSbubBJE8HmU6GmRyPw4TlSaqo6MukP8HwGK+2G46cq1qWXvr/DOu58vc3o8QgFh6LFpBOFTn3yqMnd/n4sZ4GQ5vYXpStN0ruNmAheuirVahvAX34y/Axk/96FpPYgAAACBjSFJNAAB6JQAAgIMAAPn/AACA6AAAUggAARVYAAA6lwAAF2/XWh+QAAAB/ElEQVR42uya7W3CMBCG31QM4A1aNggTlG6QbpBMkHYC1AloJ4BOABuEDcgGtBOETnD9c1ERCH/lwxeaV8oPFGP86Hy+DxMREW5Bd7gRjSDSNGn4/RiAOvm8C0ZCRD5PSkQVXSr1nK/xE3mcWimA1ZV3JYBZCIO4giQANoYxMwYS6+xKY4lT5dJPreWZY+uspqSCKPYN27GJVBDXheVSQe494ksiEWTuMXcu1dld9SARxDX1OAJ4lgjy4zDnFsC076A4adEiRwAZg4hOUSpNoCsBPDGM+HqkNGynYBCuILuWj+dgWysGsNe8nwL4GsrW0m2fxZBq9rW0rNcX5MOQ9eZD8JFahcG5g/iKT671alGAYQggpYWvpEPYWrU/HDTOfeRIX0q2SL3QN4tGhZJukVobQyXYWw7WtLDKDIuM+ZSzscyCE9PCy5IttCvnZNaeiGLNHKuz8ZVh/MXTVu/1xQKmIqLEAuJ0fNo3iG5B51oSkeKnsBi/4bG9gYB/lCytU5G9DryFW+3Gm+JLwU7ehbJrwTjq4DJU8bHcVbEV9dXXqqP6uqO5e2/QZRYJpqu2IUAA4B3tXvx8hgKp05QZW6dJqrLTNkB6vrRURLRwPHqtYgkC3cLWQAcDQGGKH13FER/NATzi786+BPDNjm1dMkfjn2pGkBHkf4D8DgBJDuDHx9BN+gAAAABJRU5ErkJggg==);
  background-color: rgba(30, 30, 30, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 1;
  -webkit-animation: cbh-circle-img-anim 1s infinite ease-in-out;
  -moz-animation: cbh-circle-img-anim 1s infinite ease-in-out;
  -ms-animation: cbh-circle-img-anim 1s infinite ease-in-out;
  -o-animation: cbh-circle-img-anim 1s infinite ease-in-out;
  animation: cbh-circle-img-anim 1s infinite ease-in-out;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  cursor: pointer;
}

.animation-img-circle:hover {
  background-color: #d32f2f !important;
}

@media screen and (max-width: 1024px) {
  .phone-button-wrapper {
    width: 150px;
    height: 150px;
    right: 60px;
    bottom: 0;
  }

  .phone-button-wrapper .animation-box {
    width: 120px;
    height: 120px;
    top: 15px;
    left: 15px;
  }

  .phone-button-wrapper .animation-box-fill {
    top: 25px;
    left: 25px;
  }

  .animation-img-circle {
    top: 45px;
    left: 45px;
  }
}

@media screen and (max-width: 768px) {
  .phone-button-wrapper {
    width: 120px;
    height: 120px;
    right: 60px;
    bottom: 0;
  }

  .phone-button-wrapper .animation-box {
    width: 100px;
    height: 100px;
    top: 10px;
    left: 10px;
  }

  .phone-button-wrapper .animation-box-fill {
    width: 80px;
    height: 80px;
    top: 20px;
    left: 20px;
  }

  .animation-img-circle {
    width: 50px;
    height: 50px;
    top: 35px;
    left: 35px;
  }
}

@media screen and (max-width: 768px) {
  .phone-button-wrapper {
    right: 30px;
    bottom: 0;
  }
}
