.stages{
    margin-top: 100px;
}

.stages .col-12{
    margin-bottom: 30px;
    padding: 0 15px;
}

.stages h2{
    margin-bottom: 30px;
    text-align: center;
    font-family: "RuberoidBold";
    font-size: 36px;
    color: #291258;
}

.stage-card{
    height: 100%;
    border-radius: 25px;
    box-shadow: 0px 0px 29px -9px rgb(0 0 0 / 25%);
    padding: 40px 30px;
}

.stage-head{
    align-items: center;
    display: flex;
    margin-bottom: 20px;
}

.stage-head h3{
    margin-bottom: 0;
    font-size: 20px;
    color: #291258;
    margin-left: 20px;
    font-family: "RuberoidBold";
}

.stage-head img{
    width: 50px;
}

.stage-card p{
    font-family: "RuberoidRegular";
    font-size: 14px;
    color: #546688;
}
