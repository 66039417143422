.navbar {
  padding-top: 0 !important;
  background: #291258;
  height: 80px;
  display: flex !important;
  justify-content: center !important;
  font-size: 1.2rem;
  position: fixed !important;
  transition: top 0.5s;
  width: 100%;
  z-index: 101;
}

.navbar .container {
  background-color: #291258;
  padding-left: 0;
  padding-right: 0;
  height: 80px;
}

.navbar-logo img {
  fill: white;
  width: 100px;
  height: 40px;
  margin-left: 10px;
  vertical-align: super;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 10px;
  list-style: none;
  justify-content: center;
}

.nav-item {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  font-family: "RuberoidMedium";
  font-size: 14px;
  color: white !important;
  text-decoration: none;
  padding: 0.5rem 1rem;
  position: relative;
}

.nav-links.active:after {
  position: absolute;
  content: "";
  height: 2px;
  bottom: -2px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 70%;
}

.nav-links:after {
  position: absolute;
  content: "";
  height: 2px;
  bottom: -2px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 0%;
  background: #fff;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.nav-links:hover:after {
  width: 70%;
  background: #fff;
}

.navbar .dropdown .btn-primary {
  background-color: transparent;
  border: transparent;
  text-decoration: underline;
  text-underline-position: under;
}

.navbar .dropdown .dropdown-toggle::after {
  display: none;
}

.navbar .dropdown .dropdown-menu {
  background-color: rgb(245, 245, 245);
}

.navbar .show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  box-shadow: none;
}

.navbar .dropdown .dropdown-menu .dropdown-item a {
  color: #000;
  text-decoration: none;
  font-family: "RuberoidMedium";
}

.navbar .dropdown .dropdown-menu .dropdown-item:active,
.navbar .dropdown .dropdown-menu .dropdown-item.active {
  background-color: rgb(245, 245, 245) !important;
}

.menu-icon {
  color: white;
  visibility: hidden;
}

.header-right {
  font-size: 14px;
  margin-left: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.header-right .consultation {
  cursor: pointer;
  font-size: 14px;
  font-family: "RuberoidSemiBold";
  margin-right: 27px;
  margin-bottom: 0;
  color: #ff7878;
}

.header-right .consultation .send-btn-modal1 {
  padding: 0;
}

.btn-primary:active:focus {
  box-shadow: none !important;
}
.header-right .btn-primary:focus {
  box-shadow: none !important;
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.header-right .lang .dropdown button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #3f2a69;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 2px solid rgb(102, 61, 119);
}

.header-right .dropdown img {
  margin-right: 8px;
}

.header-right .dropdown a {
  color: black;
}

.header-right .dropdown .dropdown-menu {
  min-width: 3rem;
  background-color: #291258;
  border: none;
  transform: translate3d(-2px, 30px, 0px) !important;
}

.header-right .dropdown-item:active {
  background-color: inherit;
}

.header-right .dropdown-toggle::after {
  display: none;
}

.header-bottom {
  display: none;
}

header .icon-links {
  display: none;
}

.langgg {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}
.langgg span {
  cursor: pointer;
}

.header-right .bottom-lang {
  display: block;
}

.bottom-lang {
  display: none;
}

@media screen and (max-width: 1199px) {
  .nav-menu {
    padding-left: 0;
    grid-gap: 5px;
  }

  .header-right {
    margin-left: 30px;
  }
}

@media screen and (max-width: 991px) {
  .nav-item {
    display: inline-grid;
    height: 75px;
  }

  .navbar .nav-dropdown .nav-links {
    padding-right: 22px;
  }

  .navbar .nav-dropdown .dropdown .dropdown-toggle::after {
    display: inline-block;
    border-top: 0.42em solid;
    border-right: 0.42em solid transparent;
    border-left: 0.42em solid transparent;
  }

  .rasul {
    position: fixed;
    width: 100%;
    top: 80px;
    left: -100%;
    transition: 0.4s;
    background: #291258;
    opacity: 0;
    font-display: swap;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-bottom: 100px;
  }

  .rasul.active {
    overflow: auto;
    height: 100vh;
    position: relative;
    opacity: 1;
    left: 0;
    transition: 0.4s;
    z-index: 1;
  }

  .nav-links {
    font-size: 20px;
    color: white !important;
    text-align: center;
    width: 100%;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 35%);
  }

  .menu-icon {
    visibility: visible;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 40%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .header-right {
    display: none;
  }

  .header-bottom {
    margin-top: 80px;
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: column;
    color: #fff;
  }

  .bottom-lang {
    display: flex;
    justify-content: center;
  }

  .header-bottom a {
    text-decoration: none;
    color: white;
  }

  .bottom-lang span {
    margin: 0 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }

  .bottom-lang span.langActive {
    border-bottom: 1px solid #fff;
  }

  .icon-links {
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }

  .icon-links a {
    opacity: 0.9;
    margin: 0 12px;
    font-size: 25px;
    color: white;
    text-decoration: none;
  }
}
