body {
    font-family: "Rubiroid", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Open Sans", "Helvetica Neue", sans-serif;
}

body.active {
    overflow: hidden;
    height: 100vh;
}

::-webkit-scrollbar {
    width: 10px;
    background: #5b5a5e;
}

::-webkit-scrollbar-track {
    box-shadow: #614d8a;
}

::-webkit-scrollbar-thumb {
    background: #3f2a69;
}

.styles_scroll-to-top__2A70v {
    right: 33px !important;
    bottom: 105px !important;
}
