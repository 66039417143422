footer {
  background: #291258;
  color: white;
  padding: 70px 0 40px;
}

footer h4 {
  margin-bottom: 30px;
  font-family: "RuberoidBold";
  font-size: 20px;
}

footer h4 a {
  text-decoration: none;
  color: #fff !important;
}

footer h4 a:hover {
  text-decoration: underline;
}

footer h5 {
  margin-bottom: 5px;
  margin-top: 15px;
  font-family: "RuberoidBold";
  font-size: 16px;
}

footer .footer-adress {
  font-family: "RuberoidRegular";
  font-size: 14px;
  color: white;
  text-decoration: none;
}

footer .footer-adress:hover {
  text-decoration: underline;
}

footer .call {
  padding-bottom: 23px;
  margin-top: 25px;
  font-family: "RuberoidBold";
  display: flex;
  flex-direction: column;
}

footer .call a {
  font-size: 15px;
  color: #fff;
  text-decoration: none;
}

footer .call a:hover {
  text-decoration: underline;
}

.footer-links {
  padding-left: 0;
  line-height: 38px;
}

.footer-links li {
  font-family: "RuberoidMedium";
  list-style: none;
}

.footer-links li a {
  text-decoration: none;
  color: #fff;
}

.footer-links li a:hover {
  text-decoration: underline;
}

.footer-form .MuiFormControl-root {
  max-width: 100%;
  width: 350px;
}

.footer-form {
  max-width: 100%;
  width: 350px;
}

footer .row {
  position: relative;
}

footer .snack {
  position: absolute;
  right: 100px;
  bottom: 100px;
  left: unset;
  max-width: 275px;
  width: 100%;
}

.footer-form .MuiInput-underline:before {
  border-bottom: 1px solid #fff !important;
}

.footer-form .MuiInput-underline:after {
  border-bottom: 2px solid #fff !important;
}

.footer-form .css-1480iag-MuiInputBase-root-MuiInput-root {
  max-width: 100%;
  width: 100%;
}

.footer-form .MuiFormLabel-root {
  font-family: "RuberoidMedium";
  color: #fff;
}

.footer-form .MuiFormLabel-root.Mui-focused {
  color: #fff !important;
}

.footer-form .MuiInputBase-input {
  color: #fff;
}

.footer-form .btn {
  margin-left: auto;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3f2a69;
  border: 2px solid rgb(102, 61, 119) !important;
  border-radius: 20px;
  color: #fff;
  width: 180px;
  max-width: 100%;
  height: 54px;
  font-family: "RuberoidBold";
  font-size: 16px;
  letter-spacing: 0.5px;
}

.footer-form .btn:hover {
  background: #291258;
}

.btn:focus {
  box-shadow: none;
}

footer .icon-links a {
  opacity: 0.9;
  margin: 0 12px;
  font-size: 25px;
  color: white;
  text-decoration: none;
}

.footer-bottom {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "RuberoidMedium";
}

@media (max-width: 1199px) {
  footer .snack {
    right: 20px;
    bottom: 100px;
  }
}

@media (max-width: 767px) {
  .footer-form {
    margin: 0 auto;
  }

  .footer-form .btn {
    margin: 40px auto 0;
  }

  footer .snack {
    transform: translateX(50%);
    right: 50%;
    bottom: 45px;
  }
}

@media (max-width: 490px) {
  .footer-bottom {
    flex-direction: column-reverse;
    font-size: 12px;
  }

  footer .icon-links {
    margin: 0 auto 10px;
  }
  footer .snack {
    bottom: 63px;
  }
}

@media (max-width: 350px) {
  footer .col-6 {
    width: 90%;
    border-bottom: 1px solid rgba(128, 128, 128, 0.507);
  }
  footer .col-md-4 {
    margin-bottom: 60px;
  }
  footer h4 {
    margin-bottom: 20px;
  }
}
