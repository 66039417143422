.sitemap{
    padding: 100px 0;
}

.sitemap h1{
    margin: 30px 0;
    font-family: "RuberoidBold";
    font-size: 36px;
    color: #291258;
}

.sitemap .sitemap-box{
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
}

.sitemap .sitemap-box h4{
    font-family: "RuberoidBold";
    color: #291258;
    font-size: 24px;
}

.sitemap .sitemap-box a{
    line-height: 29px;
    font-family: "RuberoidRegular";
    font-size: 14px;
    color: #546688;
}

@media (max-width:767px){
    .sitemap .sitemap-box{
        text-align: center;
    }
}
