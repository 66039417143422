.application {
    margin-top: 100px;
    background-color: #291258;
    padding: 90px 0;
}

.application .container {
    color: white;
    text-align: center;
}

.application h2 {
    font-family: "RuberoidBold";
    font-size: 36px;
    margin-bottom: 25px;
}

.application p {
    font-family: "RuberoidSemiBold";
    font-size: 20px;
}

.send-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.text-input .MuiInput-underline:before {
    border-bottom: 1px solid #fff !important;
}

.text-input .MuiInput-underline:after {
    border-bottom: 2px solid #fff !important;
}

.send-box .MuiFormLabel-root {
    font-family: "RuberoidMedium";
    color: #fff;
}

.send-box .MuiFormLabel-root.Mui-focused {
    color: #fff !important;
}

.send-box .MuiInputBase-input {
    color: #fff;
}

.send-box .text-input {
    max-width: 300px;
    width: 100%;
    color: #fff;
}

.send-box .send {
    background: transparent;
    border: none;
    color: #ff7878 !important;
}

.send-box .send-hover {
    margin-top: 15px;
    margin-right: 15px;
    margin-left: 200px;
}

.send {
    font-family: "RuberoidExtraBold";
    font-size: 20px;
    top: 5px;
    position: relative;
}

@media (max-width: 767px) {
    .send-box {
        display: block;
    }
    .send-box .send-hover {
        margin-top: 40px;
        margin-left: 0;
    }
}
