.modal-title {
  font-size: 23px;
  text-align: center;
  margin-bottom: 40px;
  font-family: "RuberoidMedium";
}
.all-description {
  font-family: "RuberoidRegular";
}
.vacancy-position {
  font-family: "RuberoidRegular";
  margin-bottom: 0;
  margin-bottom: 10px;
}

.vacancy-inner {
  overflow: auto;
  height: 100%;
  padding: 20px 25px;
}

.applic h2 {
  font-family: "RuberoidSemiBold";
  font-size: 20px;
}

.applic .svg-inline--fa.fa-w-16 {
  color: rgb(0, 168, 0) !important;
  margin-right: 10px;
  margin-bottom: -5px;
  font-size: 40px;
}

.applic p {
  font-family: "RuberoidRegular";
  font-size: 14px;
  margin-bottom: 20px;
}

.applic a {
  color: white;
  font-family: "RuberoidMedium";
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  border-bottom: 1px solid grey;
}

.applic .btn {
  margin: 30px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3f2a69;
  border: 2px solid rgb(102, 61, 119);
  border-radius: 20px;
  color: #fff;
  width: 210px;
  height: 58px;
  font-family: "RuberoidSemiBold";
  text-transform: none;
  font-size: 18px;
  letter-spacing: 0.5px;
}

.vacancy-inner .send-box .text-input {
  max-width: none;
}

.vacancy-inner .closebtn {
  position: absolute;
  right: 25px;
}

.closebtn {
  font-family: "RuberoidMedium";
  display: flex;
  display: -webkit-flex;
  justify-content: end;
  -webkit-justify-content: flex-end;
  cursor: pointer;
  font-size: 20px;
}

.send-btn-modal {
  white-space: nowrap;
  padding: 20px 22px;
}

.send-btn-modal1 {
  padding: 20px 60px;
}

.modalbtn {
  font-family: "RuberoidMedium";
  margin-top: 30px;
  width: 100%;
  background-color: #3f2a69;
  border: 2px solid #3f2a69 !important;
  transition: 0.3s;
  border: none;
}

.modalbtn:hover {
  background-color: #291258;
}

.fileupload {
  cursor: pointer;
  margin-bottom: 20px;
}

.times {
  font-family: "RuberoidMedium";
  background-color: transparent;
  border: none;
  color: white;
}

.modal-under {
  margin-top: 12px;
  font-family: "RuberoidSemibold";
  font-size: 19px;
  text-align: center;
}

.modal-under a {
  color: #fff;
}

.modal-vacancy-under {
  margin-top: 19px;
  font-size: 12px;
  font-family: "RuberoidRegular";
}

#file-label {
  font-family: "RuberoidMedium";
  border-bottom: 1px solid white;
}

.modal-vacancy-under a {
  color: #fff;
}

.modal-bottom {
  font-size: 12px;
  color: #8b8b8b;
}

.modal-bottom a {
  font-size: 12px;
  color: #8b8b8b;
}
