.pricesbox h2 {
    padding-top: 90px;
    margin-bottom: 60px;
    text-align: center;
    font-family: "RuberoidBold";
    font-size: 36px;
    color: #291258;
}

.pricebox {
    padding: 40px 30px;
    text-align: center;
    box-shadow: 0px 0px 29px -9px rgb(0 0 0 / 25%);
    border-radius: 20px;
    height: 100%;
}

.pricebox a{
    text-decoration: none;
}

.pricebox a:hover{
    text-decoration-color: #291258!important;
}

.pricebox :first-child a:hover{
    text-decoration: underline;;
}

.pricebox h3 {
    height: 65px;
    font-family: "RuberoidBold";
    font-size: 26px;
    color: #291258;
}

.pricebox img {
    max-width: 100%;
    margin: 25px 0;
    height: 190px;
}

.pricebox p {
    font-family: "RuberoidRegular";
    margin-bottom: 0;
    min-height: 165px;
    text-align: start;
    font-size: 14px;
    color: #546688;
}

.pricebox h4 {
    font-family: "RuberoidExtraBold";
    font-size: 22px;
    color: #291258;
    margin-bottom: 40px;
}

.pricebox .btn {
    text-decoration: none!important;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    color: #fff;
    max-width: 210px;
    height: 64px;
    font-family: "RuberoidBold";
    font-size: 18px;
    letter-spacing: 0.1px;
    transition: 0.3s;
}

.pricebox .btn:hover {
    color: #291258;
}