.portfolio {
    padding: 100px 0;
    min-height: 100vh;
}

.portfolio a{
    text-decoration: none;
}

.portfolio h2 {
    margin: 25px 0;
    font-size: 26px;
    color: #291258;
    font-family: "RuberoidBold";
}

.portfolio h1 {
    margin: 30px 0;
    font-family: "RuberoidBold";
    font-size: 36px;
    color: #291258;
}

.portfolio .css-wigwbp{
    min-width: auto;
}

.portfolio .PrivateTabIndicator-colorSecondary-3 {
    background-color: #ff7878 !important;
    bottom: 5px;
}

.portfolio .jss5 {
    background-color: #ff7878 !important;
    bottom: 5px;
}

.portfolio .MuiTabScrollButton-root {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.portfolio .MuiTab-root {
    margin-right: 40px;
    padding: 0;
    text-transform: unset;
    font-weight: 600;
    font-family: revert;
    font-size: 18px;
    color: #291258;
    opacity: 1;
}

.portfolio .css-cjctlb-MuiButtonBase-root-MuiTab-root {
    min-width: auto;
}

.portfolio .work-card{
    width: 90%;
    margin-bottom: 60px;
}

 .work-card img{
    width: 100%;
    border-radius: 20px;
    max-width: 100%;
    height: 350px;
    object-fit: cover;
    object-position: left;
}

 .work-card-layout {
    position: relative;
}

 .work-card-layout:hover .portfolio-layout {
    transition: 0.4s;
    opacity: 1;
}

 .work-card-layout .portfolio-layout {
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.705);
    padding: 10px;
    font-family: "RuberoidSemiBold";
    color: #291258;
    text-decoration: none;
    font-size: 25px;
}

@media (max-width: 734px) {
    .portfolio .MuiTab-root {
        margin-right: 25px;
    }
}
