.blog {
    padding: 100px 0;
    min-height: 100vh;
}

.blog h1 {
    margin: 30px 0;
    font-family: "RuberoidBold";
    font-size: 36px;
    color: #291258;
}

.portfolio .css-wigwbp {
    min-width: auto;
}
.blog .css-cjctlb-MuiButtonBase-root-MuiTab-root {
    min-width: auto;
}

.blog .PrivateTabIndicator-colorSecondary-3 {
    background-color: #ff7878 !important;
    bottom: 5px;
}

.portfolio .jss5 {
    background-color: #ff7878 !important;
    bottom: 5px;
}

.blog .MuiTabScrollButton-root {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.blog .MuiTab-root {
    margin-right: 40px;
    padding: 0;
    text-transform: unset;
    font-weight: 600;
    font-family: revert;
    font-size: 18px;
    color: #291258;
    opacity: 1;
}

.blog-card {
    margin-bottom: 80px;
    margin-right: 80px;
}

.blog-card a {
    text-decoration: none;
}

.blog-card p {
    font-family: "RuberoidRegular";
}

.blog-card h3 {
    font-family: "RuberoidBold";
    color: #291258;
    font-size: 24px;
    min-height: 60px;
    height: auto;
    display: flex;
    align-items: center;
}

.blog-card .blog-mini {
    font-family: "RuberoidRegular";
    margin-top: 20px;
    color: #546688;
    font-size: 14px;
}

.blog-card .blog-parag {
    font-family: "RuberoidRegular";
    font-size: 16px;
    color: #173055;
}

.blog img {
    object-fit: cover;
    width: 100%;
    min-height: auto;
    height: 250px;
    border-radius: 35px;
}

.blog .allworks {
    color: #ff7878;
    text-decoration: none;
    font-family: "RuberoidExtraBold";
    font-size: 20px;
    display: flex;
    align-items: center;
}

.blog .allworks svg {
    margin-left: 9px;
    transition: 0.3s;
}

.blog .allworks:hover svg {
    margin-left: 20px;
}

@media (max-width: 734px) {
    .blog .MuiTab-root {
        margin-right: 25px;
    }
}

@media (max-width: 767px) {
    .blog-card {
        margin-right: 0;
    }
}
