.clients {
    margin-top: 120px;
    margin-bottom: 140px;
}

.clients h2 {
    margin-bottom: 70px;
    text-align: center;
    font-family: "RuberoidBold";
    font-size: 36px;
    color: #291258;
}

.clients img {
    max-width: 100%;
    width: auto;
    height: auto;
}

.clients .slick-track {
    display: flex;
    align-items: center;
}
