.contacts-form {
    box-shadow: 0 5px 20px 2px rgb(144, 211, 255, 0.2);
    padding: 40px 20px;
    border: 1px solid #9ed9ff;
    border-radius: 60px;
    margin-top: 120px;
    text-align: center;
}

.MuiInputBase-input{
    font-family: "RuberoidMedium";
}

.contacts-form p {
    font-family: "RuberoidSemiBold";
    font-size: 20px;
    color: #3a1e72;
}

.contacts-form h2{
    font-family: "RuberoidBold";
    color: #3a1e72;
}

.contacts-form .text-input .MuiInput-underline:before {
    border-bottom: 1px solid grey !important;
}

.contacts-form .text-input .MuiInput-underline:after {
    border-bottom: 1px solid grey !important;
}

.app-send-box .MuiFormControl-root {
    max-width: 100%;
    width: 350px;
}

.contacts-form .contacts-form .app-send-box .MuiFormLabel-root {
    color: black;
}

.MuiFormLabel-root{
    font-family: "RuberoidMedium"!important;
}

.app-send-box .MuiFormLabel-root.Mui-focused {
    color: black !important;
}

.contacts-form .app-send-box .MuiInputBase-input {
    color: #000;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
    margin-bottom: 5px;
}

.app-send-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.app-send-box .send {
    background: transparent;
    border: none;
    color: #ff7878 !important;
}

.app-send-box .send-hover {
    margin-top: 15px;
    margin-left: 200px;
}

.send svg {
    position: absolute;
    top: 6px;
    right: -19px;
    transition: 0.3s;
}

.send:hover svg {
    right: -29px;
}

@media (max-width: 767px) {
    .send-box {
        display: block;
    }
    .app-send-box .send-hover {
        margin-left: 100px;
        padding-right: 20px;
    }
}

@media (max-width: 500px){
    .app-send-box {
        display: block;
        margin-top: 40px;
    }
    .app-send-box .send-hover {
        margin-left: 0;
        padding-right: 0;
    }
}