.comment {
    margin-top: 100px;
}

.comment h2 {
    margin-bottom: 20px;
    text-align: center;
    font-family: "RuberoidBold";
    font-size: 36px;
    color: #291258;
}

.comment .slick-prev {
    width: 30px;
    height: auto;
    z-index: 1;
}

.comment .slick-next {
    width: 30px;
    height: auto;
    z-index: 1;
}

.comment .slick-dots {
    bottom: 0;
}

.comment .slick-dots li.slick-active button:before {
    opacity: 1;
    content: "";
    height: 10px;
}

.comment .slick-dots li.slick-active {
    background-color: #80ccff;
    border-radius: 30px;
    width: 20px;
    transition: all 0.3s ease;
}

.comment .slick-dots li {
    transition: all 0.3s ease;
    width: 10px;
    background-color: #b4dffc;
    height: 10px;
    border-radius: 50%;
}

.comment .slick-dots li button:before {
    content: "";
}

.comment-box {
    z-index: -2;
    box-shadow: 0 5px 20px 2px rgb(144, 211, 255, 0.2);
    margin: 40px auto;
    padding: 60px 76px 30px;
    position: relative;
    width: 950px;
    max-width: 100%;
    border: 1px solid #9ed9ff;
    border-radius: 60px;
}

.comment-box .coma {
    transform: translateX(50%);
    z-index: -1;
    right: 50%;
    position: absolute;
    top: -30px;
}

.comment-box p {
    color: #475a7f;
    text-align: center;
    font-family: "RuberoidRegular";
    font-size: 16px;
}

.comment-box .user {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.comment-box .user img{
   border-radius: 50%;
   object-fit: contain;
   object-position: center;
}

.comment-box .user h4 {
    color: #291258;
    font-family: "RuberoidExtraBold";
    font-size: 18px;
}

.comment-box .user span {
    font-family: "RuberoidRegular";
    font-size: 16px;
    color: #647494;
}

@media (max-width: 1199px) {
    .comment .slick-prev {
        left: 15px;
    }

    .comment .slick-next {
        right: 15px;
    }
}

@media (max-width: 991px) {
    .comment .slick-prev,
    .comment .slick-next {
        display: none !important;
    }

    .comment-box {
        padding: 50px 66px 30px;
    }
}

@media (max-width: 767px) {
    .comment-box .coma {
        width: 100px;
        height: auto;
    }
}

@media (max-width: 575px) {
    .comment-box {
        padding: 30px 40px 30px;
    }
    .comment-box .user h4 {
        font-size: 16px;
    }
    .comment-box .user span {
        font-size: 14px;
    }
}
