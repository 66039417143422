.vacancy {
    padding: 100px 0;
}

.vacancy-info {
    white-space: pre-wrap;
    font-family: "RuberoidMedium";
}

.vacancy h1 {
    margin: 30px 0;
    font-family: "RuberoidBold";
    font-size: 36px;
    color: #291258;
}

.vacancy-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 35px 30px;
    box-shadow: 0px 0px 29px -9px rgb(0 0 0 / 25%);
    border-radius: 30px;
}

.read-modal {
    cursor: pointer;
    text-decoration: underline;
}

.vacancy-box h2 {
    font-family: "RuberoidBold";
    font-size: 36px;
    color: #291258;
    margin-bottom: 15px;
}

.vacancy-box .btn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    color: #fff;
    max-width: 220px;
    height: 64px;
    font-size: 16px;
    letter-spacing: 0.5px;
    font-family: "RuberoidBold";
}

.vacancy-box .btn:hover {
    color: #291258;
}
